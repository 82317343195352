@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'DM Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

.biotracker-logo {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 15px;
  width: 25%;
  text-align: left;
  pointer-events: none;
}
.biopixel-logo {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px;
  width: 25%;
  text-align: right;
  cursor: pointer;
}
.title {
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 20pt;
  color: #fff;
  margin: 5px;
  line-height: 250%;
  pointer-events: none;
}
.sidebar-content {
  color: #fff;
  padding: 6px 6px;
  margin: 15px;
  
}
.tracker-status {
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  z-index: 1;
  font-size: 16pt;
  pointer-events: none;
}

.mapboxgl-map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.button-container {
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 5% 25%;
  height: 8%;
  display: flex;
  justify-content: space-between;
}

.button-container > div {
  height: 100%;
  color: white;
  margin: 0 30px;
}

.button-container > div > p {
  margin: 0;
  width: 100px;
}

.species-button-container {
  cursor: pointer;
}

.species-button {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto 15px auto;
  position: relative;
  box-sizing: border-box;
}

.species-button img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto 15px auto;
  position: relative;
  box-sizing: border-box;
}

.species-button-after {
  content: '';
  position: absolute;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 50%;
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  left: -6px;
  top: -6px;
}

.species-popup {
  z-index: 1;
  position: absolute;
  bottom: 50px;
  border-radius: 10px;
  background: rgba(75, 75, 80, 0.089);
  border: 1px solid rgb(78, 78, 78, 0.5);
  backdrop-filter: blur(10px);
  width: 250px;
  max-height: 300px;
  margin-left: -70px;
  text-align: left;
  overflow-y: auto;
}

.species-popup-header {
  padding: 10px;
  margin: 0;
  border-bottom: 1px solid rgb(78, 78, 78, 0.5);
}

.species-list {
  height: 80%;
  padding: 0;
  margin: 0;
  list-style: none;
  overflow-y: auto;
}

.species-list-item {
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  user-select: none;
}

.species-list-item:hover {
  background-color: rgba(110, 110, 117, 0.322);
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1d1b1b;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mapboxgl-popup-tip {
  display: none;
}
.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup-content {
  color: white;
  border-radius: 10px;
  background: rgba(75, 75, 80, 0.089);
  border: 1px solid rgb(78, 78, 78, 0.5);
  backdrop-filter: blur(10px);
  width: 300px;
  /* height: 250px; */
  font-size: 1rem;
}
.mapboxgl-popup-content .popup-header {
  background-size: cover;
  height: 100px; 
  text-align: left;
  padding: 10px 0 0 10px;
  text-shadow: 0.5px 0.5px #464343;
}
.mapboxgl-popup-content .popup-content {
  background-size: cover;
  min-height: 200px; 
  text-align: left;
  padding: 10px 0 0 10px;
}

.loader-container {
  text-align: center;
}
.loader {
  z-index: 5;
  position: absolute;
  top: 45%;
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
} 

.mapboxgl-marker.mapboxgl-marker-anchor-center {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.history-slider-container {
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 5% 25%;
  height: 8%;
}

.info-box {
  position: absolute;
  top: 5%;
  left: 0;
  right: 0;
  width: 60vw;
  margin: auto auto;
  color: white;
  border-radius: 10px;
  background: rgba(75, 75, 80, 0.089);
  border: 1px solid rgb(78, 78, 78, 0.5);
  backdrop-filter: blur(10px);
  /* height: 100px; */
  font-size: 1rem;
  text-align: left;
}
.info-box-header p {
  cursor: pointer;
  color: deepskyblue;
  padding-left: 20px;
}
.info-box-content {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
}
.info-box-footer {
  border: 1px solid rgb(78, 78, 78, 0.5);
  padding: 5px 20px;
  min-height: 20px;
  border-radius: 5px; 
  background: rgba(0, 0, 0, 0.5);
  margin-top: 1em;
  margin-bottom: 5px;
  font-size: 0.8rem;
}